






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Button extends Vue {
  @Prop() private text!: string;
  @Prop() private link!: string;
}











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ProfileImage extends Vue {
  @Prop() private imgSrc!: string;
  @Prop() private username!: string;
}

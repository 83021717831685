
















import { Component, Vue } from 'vue-property-decorator';
import Button from '@/components/Button.vue';
import ProfileImage from '@/components/ProfileImage.vue';
import Copy from '@/components/Copy.vue';

const wp1 = "https://wallpapertag.com/wallpaper/full/b/8/5/799701-new-mac-os-wallpapers-2880x1800-hd.jpg";
const logoUrl = "https://krakenlabsweb.com/img/aro-kraken.83653f87.png";

@Component({
  components: {
    ProfileImage,
    Copy,
    Button
  },
})
export default class Home extends Vue {}
